<template>
  <div>
    <trac-tab-window
      :sectionTitle="'Products and Inventory'"
      :subLinks="subLinks"
    />
  </div>
</template>

<script>
import MixinForScrollToTop from '../../offline-module/mixins/TabbedMixin'
export default {
  mixins: [MixinForScrollToTop],
  data() {
    return {
      subLinks: [
        {
          name: "Products",
          path: "/productsinventory/myproducts",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
        {
          name: "Categories",
          path: "/productsinventory/categories",
          selected: false,
          routerWindowClassString: 'mt-12 p-2',
          dataAttribute: "",
        },
        {
          name: "Stock Adjustment",
          path: "/productsinventory/stock-adjustment",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
        {
          name: "Stock Count",
          path: "/productsinventory/stock-count",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
        {
          name: "Inventory Transfer",
          path: "/productsinventory/inventory-transfer",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
        {
          name: "Stock Label",
          path: "/productsinventory/print-label",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
        // {
        //   name: "Retail Settings",
        //   path: "/productsinventory/categoryenable",
        //   selected: false,
        //   routerWindowClassString: 'mt-12'
        //   dataAttribute: "",
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>